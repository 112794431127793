// Generated by Framer (91d32d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const cycleOrder = ["bLMMF2waf", "jBCbRIPyo"];

const serializationHash = "framer-ho5P5"

const variantClassNames = {bLMMF2waf: "framer-v-1lwd5hj", jBCbRIPyo: "framer-v-1074thf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "bLMMF2waf", "Variant 2": "jBCbRIPyo"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "bLMMF2waf"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "bLMMF2waf", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter770fy3 = activeVariantCallback(async (...args) => {
setVariant("jBCbRIPyo")
})

const onMouseLeave1h7ajlf = activeVariantCallback(async (...args) => {
setVariant("bLMMF2waf")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1lwd5hj", className, classNames)} data-framer-name={"Variant 1"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"bLMMF2waf"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnter770fy3} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({jBCbRIPyo: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeave1h7ajlf}}, baseVariant, gestureVariant)}><motion.div className={"framer-g3q069-container"} layoutDependency={layoutDependency} layoutId={"gOnrit1IM-container"}><Phosphor color={"rgb(133, 133, 133)"} height={"100%"} iconSearch={"House"} iconSelection={"ArrowUpRight"} id={"gOnrit1IM"} layoutId={"gOnrit1IM"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"regular"} width={"100%"} {...addPropertyOverrides({jBCbRIPyo: {color: "rgb(189, 189, 189)"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-ho5P5[data-border=\"true\"]::after, .framer-ho5P5 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ho5P5.framer-x0rs5n, .framer-ho5P5 .framer-x0rs5n { display: block; }", ".framer-ho5P5.framer-1lwd5hj { height: 28px; overflow: visible; position: relative; width: 30px; }", ".framer-ho5P5 .framer-g3q069-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 28
 * @framerIntrinsicWidth 30
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"jBCbRIPyo":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerXLwMcciAU: React.ComponentType<Props> = withCSS(Component, css, "framer-ho5P5") as typeof Component;
export default FramerXLwMcciAU;

FramerXLwMcciAU.displayName = "Service card icon";

FramerXLwMcciAU.defaultProps = {height: 28, width: 30};

addPropertyControls(FramerXLwMcciAU, {variant: {options: ["bLMMF2waf", "jBCbRIPyo"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerXLwMcciAU, [...PhosphorFonts])